import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'errorMessage',
    'loadingMessage',
    'results',
    'steps',
    'title',
  ];

  static values = {
    connected: Boolean,
    currentStep: String,
    errorMessage: String,
    loadingMessage: String,
    importedTests: Array,
    storedTests: Array,
    testResults: Array,
  };

  connect() {
    this._sendToReader('GET_STATE');

    this._handleReaderEvents = this._handleReaderEvents.bind(this);
    document.addEventListener('message', this._handleReaderEvents, false);
  }

  disconnect() {
    console.log('DISCONNECT');
    document.removeEventListener('message', this._handleReaderEvents, false);
  }

  cleanDevice() {
    this._sendToReader('CLEAN_DEVICE');
  }

  readTests() {
    this.loadingMessageValue = 'READING RESULTS';
    this.currentStep = 'loading';
    this._sendToReader('READ_TESTS', { testIds: this.storedTestsValue });
    this._updateUI();
  }

  async importResults() {
    // TODO: Form validation
    const results = this.testResultsValue.map((result) => {
      const { date } = result;
      const $input = this.resultsTarget.querySelector(`input[id='${date}']`);
      return { ...result, sequence_number: $input.value };
    });

    const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    const response = await fetch('/test_results', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ results }),
    });

    // TODO: Handle errors
    if (!response.ok) {
      alert(response.statusText);
      return;
    }

    this.currentStep = 'next-test';
    this._updateImportedTests();
    this._updateUI();
  }

  //
  // Private
  // -------------------------------------------------------------------------------------------------

  // Sends events to the reader
  _sendToReader(eventName, payload = {}) {
    const data = JSON.stringify({ eventName, payload });
    window.ReactNativeWebView.postMessage(data);
  }

  // Handles events received from the reader
  _handleReaderEvents(event) {
    const { eventName, payload } = JSON.parse(event.data);

    switch(eventName) {
      case 'READER_STATE':
        this._handleReaderState(payload);
        break;
      case 'TEST_RESULTS':
        this._handleTestResults(payload);
        break;
    }
  }

  // Handles reader state changes
  _handleReaderState(state) {
    const { name, connected, storedTests, workflowState } = state;
    console.log('imported', this.importedTestsValue);
    this.connectedValue = connected;
    console.log('before', storedTests);
    this.storedTestsValue = storedTests.filter(t => this.importedTestsValue.indexOf(t) === -1);
    console.log('after', this.storedTestsValue);
    this.errorValue = null;
    this.loadingMessageValue = null;

    // Change the current state if necessary
    this.currentStep = (() => {
      if (!connected) {
        return 'disconnected';
      }

      switch(workflowState) {
        case 'READY':
          return this.storedTestsValue.length > 0 ? 'existing-tests' : 'run-test';
        case 'TEST COMPLETE':
          this.readTests();
          return 'loading';
        case 'CLEAN MODE':
          return 'clean-reader';
        case 'USED CASSETTE ERROR':
          this.errorMessageValue = 'Please remove the cassette from the reader.';
          return 'error';
        case undefined:
          this.errorMessageValue = 'Something went wrong. Please try turning the reader off and on.';
          return 'error';
        default:
          this.loadingMessageValue = workflowState;
          return 'loading';
      }
    })();

    console.log(`Received state from ${name}`, state);
    this._updateUI();
  }

  // Handles receiving test results
  _handleTestResults({ testResults }) {
    console.log(`Received test results`, testResults);

    // Find invalid test results, where barcode is missing
    const invalidResults = testResults.filter((result) => {
      const { barcode } = result.cassette;
      return barcode === null;
    });
    this.testResultsValue = testResults;

    if (testResults.length === invalidResults.length) {
      this.errorMessageValue = 'The barcode could not be read. Please adjust and reread the cassette.';
      this.currentStep = 'error';
    } else {
      this.currentStep = 'import-results';
    }

    this._updateUI();
  }

  // Remove imported tests from storedTests
  _removeImportedTests() {
    const importedIds = this.testResultsValue.map(r => r.date);
    return this.storedTestsValue.filter(id => importedIds.indexOf(id) === -1);
  }

  // Add the latest tests to imported tests
  _updateImportedTests() {
    const latestTestIds = this.testResultsValue.map(t => t.date);
    this.importedTestsValue = this.importedTestsValue.concat(latestTestIds);
  }

  // Updates the page UI
  _updateUI() {
    console.log('Current step', this.currentStep);

    // Hide/show steps
    const steps = this.stepsTarget.querySelectorAll('[data-step]');
    [].forEach.call(steps, (step) => {
      const name = step.getAttribute('data-step');
      const title = step.getAttribute('data-title');

      // If this is not the current step, hide and return
      if (name !== this.currentStep) {
        step.style.display = 'none';
        return;
      }

      // Set the title
      this.titleTarget.innerText = title;

      // Show
      step.style.display = 'block';

      // Is this an error?
      if (this.errorMessageValue) {
        this.errorMessageTarget.innerText = this.errorMessageValue;
      }

      // Is this a loading message?
      if (this.loadingMessageValue) {
        this.loadingMessageTarget.innerText = this.loadingMessageValue;
      }

      // Build the test results
      let resultsTable = `
        <form>
          <div class="mt-6 sm:mt-5">
      `;
      this.testResultsValue.forEach((result) => {
        const { barcode, date } = result.cassette;
        const [_, sampleId] = (barcode || '').split(',');

        resultsTable += `
        <div class="mt-6">
          <label for="${date}" class="block text-sm font-bold leading-5 text-gray-700">
            ${sampleId || 'Barcode could not be read, will not be imported.'}
          </label>
          <div class="mt-1">
            <div class="max-w-lg rounded-md shadow-sm">
              <input id="${date}" class="form-input block w-full transition duration-150 ease-in-out">
            </div>
          </div>
        </div>
        `;
      });

      resultsTable += `
          </div>
        </form>
      `;

      this.resultsTarget.innerHTML = resultsTable;
    });
  }
}
