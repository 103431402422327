// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("trix");
require("@rails/ujs").start()
require("@rails/actiontext");
require("turbolinks").start()
require("channels")
require("alpinejs");

import '../stylesheets/application.scss';

// NOTE: replace Mentions with AlpineJS
import 'controllers';

// Timestamps
import dayjs from 'dayjs';
document.addEventListener('turbolinks:load', () => {
  const timestamps = document.querySelectorAll('[data-timestamp]');

  timestamps.forEach(el => {
    const ts = el.getAttribute('data-timestamp');
    const d = dayjs(ts);
    const format = el.getAttribute('data-format') === "long"
      ? 'dddd, MMMM, D, YYYY, h:mm A'
      : 'MM/DD/YY h:mm A';

    el.innerText = d.format(format);
  });
});

// Tooltips
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
document.addEventListener('turbolinks:load', () => {
  tippy('[data-tippy-content]', {
    animation: false,
  });
});

// Trim 2FA code
document.addEventListener('turbolinks:load', () => {
  const form = document.querySelector('form#two_factor_auth');
  const input = form && form.querySelector('#auth_code');

  if (form && input) {
    form.addEventListener('submit', (e) => {
      input.value = input.value.trim();
    });
  }
});